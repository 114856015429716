<template>
  <div v-if="wallbox" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="wallbox">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('manufacturer')"
            label-for="manufacturer"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="wallbox.manufacturer"
              type="text"
              :placeholder="$t('manufacturer')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('type')"
            label-for="type"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="wallbox.type" type="text" :placeholder="$t('type')" />
          </b-form-group>

          <legend>
            <strong>Dokumente</strong>
          </legend>

          <DropzoneUpload
            v-if="!isNew"
            :complete="isAttachmentUploadComplete"
            :parentId="wallbox.id"
            :uploadCallback="uploadWallboxAttachment"
            v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
          />

          <AttachmentList
            :attachments="wallbox.attachments"
            v-on:attachment-list:delete="onDeleteWallboxAttachment"
          ></AttachmentList>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Parameter</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('maxNetInputPower')"
            label-for="maxNetInputPower"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="wallbox.maxNetInputPower"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" kW"
              locale="de-DE"
              :placeholder="$t('maxNetInputPower')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('maxNetOutputPower')"
            label-for="maxNetOutputPower"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="wallbox.maxNetOutputPower"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" kW"
              locale="de-DE"
              :placeholder="$t('maxNetOutputPower')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('minChargingPower')"
            label-for="minChargingPower"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="wallbox.minChargingPower"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" kW"
              locale="de-DE"
              :placeholder="$t('minChargingPower')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('maxChargingPower')"
            label-for="maxChargingPower"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="wallbox.maxChargingPower"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" kW"
              locale="de-DE"
              :placeholder="$t('maxChargingPower')"
            />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('activePowerControllable')"
            label-for="activePowerControllable"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('activePowerControllable')"
              v-model="wallbox.activePowerControllable"
              :options="optionsYesNo"
              :show-labels="false"
              :allow-empty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge :variant="yesNoColor(option)">{{ $t(option) }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge :variant="yesNoColor(option)">
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('chargingType')"
            label-for="chargingType"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('chargingType')"
              v-model="wallbox.chargingType"
              :options="getEnumValues('ChargingType')"
              :show-labels="false"
              :allow-empty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
              <template slot="option" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
            </multiselect>
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('connectionType')"
            label-for="connectionType"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('connectionType') + ' ' + $t('select')"
              v-model="wallbox.connectionTypes"
              :options="getEnumValues('WallboxConnectionType')"
              :close-on-select="false"
              :clear-on-select="false"
              :multiple="true"
              :taggable="true"
              :show-labels="true"
              :allow-empty="true"
              :selectLabel="$t('select')"
              :selectedLabel="$t('selected')"
              :deselectLabel="$t('remove_selection')"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
              <template slot="option" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
              <template slot="tag" slot-scope="{ option }">
                <b-badge>
                  {{ $t(option) }}
                </b-badge>
              </template>
            </multiselect>
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('shiftFactorCosphiType')"
            label-for="shiftFactorCosphiType"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('shiftFactorCosphiType')"
              v-model="wallbox.shiftFactorCosphiType"
              :options="getEnumValues('ShiftFactorCosphiType')"
              :show-labels="false"
              :allow-empty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
              <template slot="option" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="wallbox"
            modelEditRoute="WallboxEditPage"
            modelListRoute="WallboxesPage"
            modelRouteParamName="wallboxNumber"
            :updateCallback="updateWallbox"
            :createCallback="createWallbox"
            :deleteCallback="deleteWallbox"
            :fetchCallback="fetchWallboxes"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import { yesNo } from '@/helpers/enums';
import { getYesNoStateColor } from '@/helpers/colors';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';
import InputNumber from 'primevue/inputnumber';
import Multiselect from 'vue-multiselect';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'WallboxEditPage',
  components: {
    Widget,
    Breadcrumbs,
    DropzoneUpload,
    AttachmentList,
    InputNumber,
    Multiselect,
    ButtonBar,
  },
  props: {
    wallboxNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      user: {},
      buttonsDisabled: false,
      isAttachmentUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getWallbox', 'getWallboxByNumber', 'getEnumValues']),
    buttons() {
      return {
        showDeleteButton: this.wallbox && this.wallbox.number !== '_new' ? true : false,
      };
    },
    optionsYesNo() {
      return yesNo();
    },
    wallbox() {
      return this.getWallboxByNumber(this.number);
    },
    isNew() {
      return this.wallbox.id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('wallbox'), route: { name: 'WallboxesPage' } },
        { name: this.wallbox.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initWallbox',
      'fetchWallboxes',
      'fetchWallboxByNumber',
      'fetchConnectionTypes',
      'updateWallbox',
      'createWallbox',
      'deleteWallbox',
      'deleteWallboxAttachment',
      'uploadWallboxAttachment',
      'fetchEnumValues',
    ]),
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    onDeleteWallboxAttachment(event) {
      this.deleteWallboxAttachment({
        wallboxId: this.wallbox.id,
        attachmentId: event.attachmentId,
      });
    },
  },
  async mounted() {
    await this.fetchConnectionTypes();
    await this.fetchEnumValues('WallboxConnectionType');
    await this.fetchEnumValues('ShiftFactorCosphiType');
    await this.fetchEnumValues('ChargingType');
    if (!this.wallbox && this.wallboxNumber) {
      this.number = this.wallboxNumber;
      this.fetchWallboxByNumber(this.number);
    } else {
      this.initWallbox();
      this.number = '_new';
    }
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadWallboxAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style scoped lang="scss">
.wallboxContacts {
  text-align: right;
}
.trash-contact {
  cursor: pointer;
}
</style>
